import React, { useEffect, useState } from 'react';
import { withWorkforms, Loader } from '@manakin/app-core';
import { compose } from 'recompose';
import { GQL_FETCH_STYLESHEET } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useLocation } from 'react-router-dom';
import { DefaultFrontPage } from '../DefaultPages';
import Questions from './Questions';
const queryString = require('query-string');
import { useTranslation } from 'react-i18next';

const PreviewMasterclass = (props) => {
	const { match, workformsData, changeColor } = props;
	const query = match.params.query;
	const { rawData = {} } = workformsData;
	const elementData = queryString.parse(query);
	const location = useLocation();
	const { t } = useTranslation();

	const { data = {}, loading } = useQuery(GQL_FETCH_STYLESHEET, {
		variables: {
			id: elementData.styleSheet || '',
		},
	});

	//state hooks
	const [factCards, setFactCards] = useState([]);
	const [step, setStep] = useState(0);
	const [items, setItems] = useState([]);
	const [itemTypes, setItemTypes] = useState([]);

	useEffect(() => {
		if (elementData && elementData.items) {
			if (Array.isArray(elementData.items)) {
				setItems(elementData.items);
				setItemTypes(elementData.itemTypes);
			} else {
				setItems([elementData.items]);
				setItemTypes([elementData.itemTypes]);
			}
		}
	}, [query]);

	//effect hooks
	useEffect(() => {
		if (!loading) {
			const isFilm = location.pathname && location.pathname.includes('Film') ? true : false;
			window.scrollTo(0, 0);
			const obj = {
				...elementData,
				headerImage: {
					url: elementData.headerImage,
				},
				image: {
					url: elementData.image,
				},
				workforms: [
					...items.map((item, idx) => ({
						id: item,
						type: itemTypes[idx],
					})),
				],
				options: {
					showFeedback: isFilm,
					nextButton: true,
				},
			};

			changeColor({
				...data.styleSheet,
				location: location.pathname,
			});
			workformsData.loadWorkforms({ ...obj });
		}
	}, [loading]);

	useEffect(() => {
		if (!workformsData.loading) {
			if (workformsData.rawData && workformsData.rawData.factCards && workformsData.rawData.factCards.length) {
				setFactCards([...workformsData.rawData.factCards]);
			}
		}
	}, [workformsData]);

	//functions
	const handleClick = () => {
		setStep(1);
	};

	const handleFinish = () => {
		setStep(2);
	};

	if (loading || workformsData.loading) {
		return (
			<div>
				<Loader fullScreen={true} />
			</div>
		);
	}
	return (
		<React.Fragment>
			{step === 0 && <DefaultFrontPage playButton={true} {...rawData} factCards={factCards} onClick={handleClick} controls={false} />}
			{step === 1 && (
				<Questions
					{...workformsData}
					onFinish={handleFinish}
					location={props.location}
					background={rawData.borderColor || ''}
					controls={false}
				/>
			)}
			{step === 2 && (
				<DefaultFrontPage
					buttonText={t('common.replay')}
					{...rawData}
					factCards={factCards}
					controls={false}
					onClick={handleClick}
				/>
			)}
		</React.Fragment>
	);
};

export default compose(
	connect(null, (dispatch) => ({
		changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
	})),
	withWorkforms()
)(PreviewMasterclass);
