import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageLayout from '../PageLayout';
import { GQL_FETCH_APP_USER_FOR_STORE, GQL_FETCH_SERVER_INFO } from '../../graphql';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { Loader } from '@manakin/app-core';
import Cookies from 'js-cookie';
import { useQuery } from '@apollo/react-hooks';

const styles = (theme) => ({
	wrapper: {
		...theme.manakin.smallWrapper,
		maxWidth: '550px',
	},
	center: {
		textAlign: 'center',
	},
});

const PayScreen = (props) => {
	const { classes, form, APP_USER = {} } = props;
	const { loading } = APP_USER;

	const { loading: serverLoading, error, data: serverData = {} } = useQuery(GQL_FETCH_SERVER_INFO);
	const { serverInfo = {} } = serverData;

	const [price, setPrice] = useState(0);

	useEffect(() => {
		if (!loading) {
			let arr = [];

			const shopCookies = JSON.parse(Cookies.get('shop'));

			if (shopCookies) {
				for (let [key, value] of Object.entries(shopCookies)) {
					if (key.indexOf('trainingMandatory') !== -1 && value) {
						arr.push({ year: getSecondPart(key) });
					}
				}
			}

			let _price = Math.ceil(arr.length * '3250' * (1 + 0.21));
			if (serverInfo.currentYear && serverInfo.currentYear === 2020) {
				_price = Math.ceil(arr.length * '3350' * (1 + 0.21));
			}
			if (serverInfo.currentYear && serverInfo.currentYear >= 2021) {
				_price = Math.ceil(arr.length * '3400' * (1 + 0.21));
			}
			if (serverInfo.currentYear && serverInfo.currentYear >= 2022) {
				_price = Math.ceil(arr.length * '3465' * (1 + 0.21));
			}
			if (serverInfo.currentYear && serverInfo.currentYear >= 2023) {
				_price = Math.ceil(arr.length * '3760' * (1 + 0.21));
			}
			if (serverInfo.currentYear && serverInfo.currentYear >= 2024) {
				_price = Math.ceil(arr.length * '4136' * (1 + 0.21));
			}
			if (serverInfo.currentYear && serverInfo.currentYear >= 2025) {
				_price = Math.floor(arr.length * '4343' * (1 + 0.21)); // Floor because customer wants it that way
			}

			setPrice(_price);
			props.onPrice(_price);
		}
	}, [loading, serverLoading]);

	//functions
	const getSecondPart = (str) => {
		return str.split('trainingMandatory')[1];
	};

	const handleChange = (data) => {
		props.onReady(true);
		props.onBank(data.value);
	};

	if (loading) {
		return (
			<div>
				<Loader fullScreen={true} />
			</div>
		);
	}
	return (
		<PageLayout title="Betalen" intro={`Totaal ${parseFloat(price / 100).toFixed(2)} (incl BTW)`}>
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<div className={classes.center}>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default compose(
	graphql(GQL_FETCH_APP_USER_FOR_STORE, {
		name: 'APP_USER',
		options: { fetchPolicy: 'no-cache' },
	}),
	withStyles(styles)
)(PayScreen);
