export const styles = (theme) => ({
	root: {
		padding: '14rem 0',
		backgroundColor: '#F2F2F2',
		minHeight: '100vh',
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
	},
	smallWrapper: {
		...theme.manakin.smallWrapper,
		maxWidth: '700px',
	},
	stepperRoot: {
		justifyContent: 'center',
		margin: '30px 0 60px',
	},
	stepRoot: {
		flex: '0 0 auto',
		fontFamily: theme.manakin.defaultTitleFont,
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	mainButtonStyle: {
		padding: '2rem',
		minWidth: '30rem',
		marginBottom: '2.8rem',
	},
	special: {
		padding: '2rem',
		border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
	},
});
