import React, { useCallback, useState } from 'react';
import useStyles from './styles';
import Introduction from './parts/Introduction';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { GQL_CURRENT_APP_USER, GQL_PAY } from '../graphql';
import { useQuery, useMutation } from 'react-apollo';
import { Loader } from '@manakin/app-core';
import { withForm } from '@manakin/core';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { logout } from '@manakin/authentication/actions';
import { useDispatch } from 'react-redux';
import DefaultWrapper from './parts/DefaultWrapper';
import { useHistory } from 'react-router-dom';

const formBundle = {

};

const PayScreen = (props) => {
	const { form } = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const onLogout = useCallback(() => dispatch(logout()), [dispatch]);
	const [paymentError, setPaymentError] = useState(false);
	const history = useHistory();
    const products = Cookies.get('shop') ? JSON.parse(Cookies.get('shop')) || {} : {};

	let price = 0;
	let productArr = [];
	for (const [key, value] of Object.entries(products)) {
		productArr.push(key);
		if (value && value.priceCents) {
			price += value.priceCents;
		}
	}
	price = Math.ceil(price * (1 + 0.21));

	//mutation
	const [mutate] = useMutation(GQL_PAY, {
		onCompleted: (result) => {
			if (result && !result.errors) {
				if (result.pay && result.pay.orderId) {
					history.push(`/auth/payment-status/${result.pay.orderId}`);
				}
			} else {
				setPaymentError(true);
			}
		},
	});

	//query
	const { data, loading, error } = useQuery(GQL_CURRENT_APP_USER);
	const { currentAppUser } = data || {};

	const handleClick = () => {
		form.onValidate().then(() => {
			form.onSubmit().then((result) => {
				const appUrl = process.env.REACT_APP_APP_URL || 'http://localhost:3000';

                const {school} = currentAppUser || {}

				if (school) {
					const street = school.street || '';
					const buildingNumber = school.buildingNumber || '';
					const zipCode = school.zipCode || '';
					const city = school.city || '';
					const country = school.country || '';

					mutate({
						variables: {
							input: {
								description: t('authentication.webshop.pay-screen.payment-description'),
								entranceCode: 'CBD',
								returnURL: `${appUrl}/auth/payment-status`,
								street: street,
								buildingNumber: buildingNumber,
								zipCode: zipCode,
								city: city,
								country: country,
								productIds: productArr,
							},
						},
					});
				}
			});
		});
	};

	const handleReturn = () => {
		onLogout();
	};

	const handleBack = () => {
		history.goBack();
	};

	if (loading) return <Loader fullscreen />;
	if (error || !currentAppUser || paymentError) {
		return (
			<DefaultWrapper>
				<Typography>{t('authentication.webshop.pay-screen.error')}</Typography>
				<div className={classes.buttonGroup}>
					<Button onClick={handleReturn} color="primary" variant="contained" className={classes.mainButtonStyle}>
						{t('common.logout')}
					</Button>
				</div>
			</DefaultWrapper>
		);
	}
	return (
		<DefaultWrapper>
			<Introduction
				title={t('authentication.webshop.pay-screen.title')}
				introduction={t('authentication.webshop.pay-screen.price', { price: parseFloat(price / 100).toFixed(2) })}
			/>
			<div className={classes.content}>
				<div className={classes.wrapper}>
				</div>
			</div>
			<div className={classes.buttonGroup}>
				<Button onClick={handleBack} color="primary">
					{t('common.back')}
				</Button>
				<Button onClick={handleClick} color="primary" variant="contained" className={classes.mainButtonStyle}>
					{t('common.continue')}
				</Button>
			</div>
		</DefaultWrapper>
	);
};

export default withForm(formBundle)(PayScreen);
